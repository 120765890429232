// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-do-not-sell-info-index-js": () => import("./../../../src/pages/do-not-sell-info/index.js" /* webpackChunkName: "component---src-pages-do-not-sell-info-index-js" */),
  "component---src-pages-firstrun-index-js": () => import("./../../../src/pages/firstrun/index.js" /* webpackChunkName: "component---src-pages-firstrun-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-optional-offer-nt-index-js": () => import("./../../../src/pages/info/optional-offer-nt/index.js" /* webpackChunkName: "component---src-pages-info-optional-offer-nt-index-js" */),
  "component---src-pages-lp-adblocker-index-js": () => import("./../../../src/pages/lp/adblocker/index.js" /* webpackChunkName: "component---src-pages-lp-adblocker-index-js" */),
  "component---src-pages-lp-chroma-index-js": () => import("./../../../src/pages/lp/chroma/index.js" /* webpackChunkName: "component---src-pages-lp-chroma-index-js" */),
  "component---src-pages-lp-dd-3-tide-spa-index-js": () => import("./../../../src/pages/lp/dd3-tide-spa/index.js" /* webpackChunkName: "component---src-pages-lp-dd-3-tide-spa-index-js" */),
  "component---src-pages-lp-dd-3-tide-spc-index-js": () => import("./../../../src/pages/lp/dd3-tide-spc/index.js" /* webpackChunkName: "component---src-pages-lp-dd-3-tide-spc-index-js" */),
  "component---src-pages-lp-direct-1-chroma-exp-index-js": () => import("./../../../src/pages/lp/direct1-chroma-exp/index.js" /* webpackChunkName: "component---src-pages-lp-direct-1-chroma-exp-index-js" */),
  "component---src-pages-lp-direct-1-chroma-index-js": () => import("./../../../src/pages/lp/direct1-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-direct-1-chroma-index-js" */),
  "component---src-pages-lp-direct-chroma-index-js": () => import("./../../../src/pages/lp/direct-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-direct-chroma-index-js" */),
  "component---src-pages-lp-direct-chroma-v-130-b-index-js": () => import("./../../../src/pages/lp/direct-chroma-v130b/index.js" /* webpackChunkName: "component---src-pages-lp-direct-chroma-v-130-b-index-js" */),
  "component---src-pages-lp-direct-chroma-v-130-exp-index-js": () => import("./../../../src/pages/lp/direct-chroma-v130-exp/index.js" /* webpackChunkName: "component---src-pages-lp-direct-chroma-v-130-exp-index-js" */),
  "component---src-pages-lp-direct-chroma-v-130-index-js": () => import("./../../../src/pages/lp/direct-chroma-v130/index.js" /* webpackChunkName: "component---src-pages-lp-direct-chroma-v-130-index-js" */),
  "component---src-pages-lp-direct-jump-chroma-index-js": () => import("./../../../src/pages/lp/direct-jump-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-direct-jump-chroma-index-js" */),
  "component---src-pages-lp-download-index-js": () => import("./../../../src/pages/lp/download/index.js" /* webpackChunkName: "component---src-pages-lp-download-index-js" */),
  "component---src-pages-lp-prd-best-index-js": () => import("./../../../src/pages/lp/prd-best/index.js" /* webpackChunkName: "component---src-pages-lp-prd-best-index-js" */),
  "component---src-pages-lp-qst-index-js": () => import("./../../../src/pages/lp/qst/index.js" /* webpackChunkName: "component---src-pages-lp-qst-index-js" */),
  "component---src-pages-lp-ripple-index-js": () => import("./../../../src/pages/lp/ripple/index.js" /* webpackChunkName: "component---src-pages-lp-ripple-index-js" */),
  "component---src-pages-lp-spb-index-js": () => import("./../../../src/pages/lp/spb/index.js" /* webpackChunkName: "component---src-pages-lp-spb-index-js" */),
  "component---src-pages-lp-tide-chroma-index-js": () => import("./../../../src/pages/lp/tide-chroma/index.js" /* webpackChunkName: "component---src-pages-lp-tide-chroma-index-js" */),
  "component---src-pages-lp-tide-download-index-js": () => import("./../../../src/pages/lp/tide-download/index.js" /* webpackChunkName: "component---src-pages-lp-tide-download-index-js" */),
  "component---src-pages-lp-tide-index-js": () => import("./../../../src/pages/lp/tide/index.js" /* webpackChunkName: "component---src-pages-lp-tide-index-js" */),
  "component---src-pages-lp-tide-instructions-index-js": () => import("./../../../src/pages/lp/tide-instructions/index.js" /* webpackChunkName: "component---src-pages-lp-tide-instructions-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-ty-amo-thank-you-index-js": () => import("./../../../src/pages/ty/amo-thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-amo-thank-you-index-js" */),
  "component---src-pages-ty-sof-2-thank-you-index-js": () => import("./../../../src/pages/ty/sof2-thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-sof-2-thank-you-index-js" */),
  "component---src-pages-ty-sof-thank-you-index-js": () => import("./../../../src/pages/ty/sof-thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-sof-thank-you-index-js" */),
  "component---src-pages-ty-thank-you-index-js": () => import("./../../../src/pages/ty/thank-you/index.js" /* webpackChunkName: "component---src-pages-ty-thank-you-index-js" */),
  "component---src-pages-uninstall-index-js": () => import("./../../../src/pages/uninstall/index.js" /* webpackChunkName: "component---src-pages-uninstall-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */)
}

